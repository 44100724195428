.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: black;
}

.App-link {
  color: #09d3ac;
}


.container-1 {
  display:flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: stretch;
  flex-direction: column;
}

.NameTab {
  order:2; 
}

.drop_down {
  margin-top: 50px;
}

canvas {
  border:1px solid #ffffff;
  background-color: white;
  width: 'auto';
  height: 300px;
  margin-top: 50px;
  display: block;
}

.sortPage {
  order: 3;
  font-size: 80px;
  position: right;
}

.ProjectsTab {
  order:3;
}

.resume {
  order: 2;
  font-size: 160%;
  position: right;
}

.firstLine {
  font-size: 80%;
  font-family: "Helvetica";
  font-weight: normal;
  color:black;
}

.sortType {
  margin-top: 0px;
  align-content: "right";
  font-size: 10px;
  font-family: "Helvetica";
  font-weight: 300;
  color:black;
}

textarea { 
  resize: none;
  border: none; 
  margin-left:20px;
  font-family: "Helvetica";
  background-color: #ffffff;
  color: #000000 !important; 
  font-size: 2.5vw
}



a:link {
  text-align: center;
  color:black;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  text-align: center;
  color:black;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  text-align: center;
  color:black;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
}

.topright {
position: right;
}

.sort {
  font-size:120%;
  color: darkslategray;
  position: left; 
}

.topcenter {
  position:right;
}

.Typist .Cursor {
  display: inline-block; 
}

.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 500ms linear infinite; 
}

@keyframes blink {
  0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}
